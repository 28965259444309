export function removeAllTrailingSlashes(str: string) {
  let i = str.length;
  while (str[--i] === "/");
  return str.slice(0, i + 1);
}

export function removeTrailingNumbers(str: string) {
  return str.trim().replace(/ \d+$/, "").trim();
}

export function replaceMultipleSlashes(input: string): string {
  return input.replace(/\/+/g, "/").replace(/\/+$/, "");
}

export function addNumberToEnd(str: string): string {
  const numberRegex = /-(\d+)$/;
  const match = str.match(numberRegex);

  if (match?.[1]) {
    const number = parseInt(match[1]) + 1;
    return str.replace(numberRegex, `-${number}`);
  } else {
    return `${str}-2`;
  }
}

// Because Skolverket uses HTML for their knowledge requirements
// like some fucking cowboys we need to parse the hell out of it.
// This function fixes the issue where they put the `.` inside a `strong`-tag (WHO DOES THAT?)
const moveDotOutsideStrong = (input: string): string => {
  const strongTagRegex = /(<strong>.*?)(\.\s*)(<\/strong>)/g;
  return input.replace(
    strongTagRegex,
    (_, openTag, dotAndSpace, closeTag) =>
      `${openTag}${closeTag}${dotAndSpace}`,
  );
};

export function splitHtmlByParagraphAndSentence(html: string): string[][] {
  html = moveDotOutsideStrong(html);
  html = html.replaceAll("<br /><br />", "</p><p>");
  html = html.replaceAll("<br><br>", "</p><p>");
  const paragraphRegex = /<p>(.*?)<\/p>/g;
  let match;
  const paragraphs: string[][] = [];

  while ((match = paragraphRegex.exec(html)) !== null) {
    const paragraph = match[1];
    if (!paragraph) continue;
    const sentenceParts = paragraph.split(/(?<=[.!?])\s+(?=<|[A-ZÅÄÖ])/);
    const cleanedSentences = sentenceParts.map((part) => part.trim());
    paragraphs.push(cleanedSentences);
  }

  return paragraphs;
}

export function withDefault(
  string: string | undefined | null,
  defaultValue: string,
): string {
  if (typeof string === "string" && string.trim() !== "") {
    return string;
  }
  return defaultValue;
}

export function extractSpecialChars(str: string): string[] {
  const specialChars =
    str.match(/[^a-zåäöüæœ0-9.,;:!?'"()\[\]{}\-_+=@#$%^&*|\/\s]/gi) ?? [];
  return [...new Set(specialChars)];
}

export function removeFancyQuotes(str: string): string {
  return str.replace(/[\u2018\u2019]/g, "'").replace(/[\u201C\u201D]/g, '"');
}
